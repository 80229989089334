import '../layout/index.scss'

import {loadBlocksFromGraph} from 'blocks/blocks_graph'
import {graphql} from 'gatsby'
import {Article} from 'layout/pages/article/article'
import React, {useEffect} from 'react'
import {ArticleQueryQuery, GraphMedia} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'
import {ImageType, TemplateType} from 'types/basictypes'
import {countWords} from 'util/countWords'
import {FixedObject} from 'gatsby-image'
import {Template} from 'layout/templates/template'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

const ArticlePage: React.FC<TemplateType<ArticleQueryQuery>> = ({
	data,
	pageContext
}) => {
	const {
		page: {
			dataLayerDate,
			field_summary,
			field_description,
			field_more,
			path: { langcode },
			fields: {
				metaFragment,
				previewFragment
			},
			relationships: {
				field_background_image,
				field_blocks,
				field_authors
			}
		},
		allPagePreview: { relatedFragments }
	} = data

	const blocks = loadBlocksFromGraph(field_blocks)
	const preview = previewFragmentToPreviewItem(previewFragment)
	const description = getWysiwygFromField(field_description)
	const authors = field_authors.map(item => {
		if (item.__typename === 'paragraph__article_existing_author') {
			const author = item.relationships.field_existing_author_author
			return {
				image: getImageFromField(author.relationships.field_author_avatar_image),
				name: author.name,
				function: author.field_author_function,
				description: getWysiwygFromField(author.field_avatar_bio)
			}
		}
		if (item.__typename === 'paragraph__article_author') {
			return {
				image: getImageFromField(item.relationships.field_article_author_image),
				name: item.field_article_author_name,
				function: item.field_article_author_function,
				description: getWysiwygFromField(item.field_article_author_bio)
			}
		}
	})

	useEffect(() => {
		// @ts-ignore
		if (window['dataLayer']) {
			// @ts-ignore
			dataLayer.push({
				Author: authors.map(item => item.name),
				ContentType: 'artikel',
				WordCount: countWords(blocks),
				ContentTags: preview.tags,
				PublishDate: dataLayerDate
			})
		}
	}, [pageContext.slug])

	return (
		<Template
			preview={preview}
			pageContext={pageContext}
			lang={langcode}
			relatedFragments={relatedFragments}
			relatedJobsFragments={[]}
			content={blocks}
			metaFragment={metaFragment}
		>
			<Article
				background_image={getArticleBackgroundImage(field_background_image)}
				title={preview.title}
				description={description}
				summary={getWysiwygFromField(field_summary)}
				more={getWysiwygFromField(field_more)}
				blocks={blocks}
				authors={authors}
				preview={preview}
			/>
		</Template>
	)
}

export default ArticlePage

export const pageQuery = graphql`
	query ArticleQuery($slug: String!, $allTags: [String]) {
		page: nodeArticle(fields: {slug: {eq: $slug}}) {
			dataLayerDate: field_date(formatString: "DD/MM/YYYY")
			field_description {
				processed
			}
			field_summary {
				processed
			}
			path {
				langcode
			}
			field_more {
				processed
			}
			fields {
				metaFragment: meta {...Meta}
				previewFragment: preview {...Preview}
			}
			relationships {
				field_blocks {...Blocks}
				field_tags {name}
				field_label {name}
				field_authors {
					... on paragraph__article_author {
						field_article_author_name
						field_article_author_function
						field_article_author_bio {
							processed
						}
						relationships {
							field_article_author_image {
								...ImageMeta
								relationships {
									field_media_image {
										localFile {
											image: childImageSharp {
												fixed(width: 384) {
													...GatsbyImageSharpFixed
												}
											}
										}
									}
								}
							}
						}
					}
					... on paragraph__article_existing_author {
						relationships {
							field_existing_author_author {
								name
								field_author_function
								field_avatar_bio {
									processed
								}
								relationships {
									field_author_avatar_image {
										...ImageMeta
										relationships {
											field_media_image {
												localFile {
													image: childImageSharp {
														fixed(width: 384) {
															...GatsbyImageSharpFixed
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				field_background_image {
					...ImageMeta
					relationships {
						field_media_image {
							localFile {
								mobile: childImageSharp {
									fixed(width: 375, height: 700, cropFocus: CENTER) {
										...GatsbyImageSharpFixed
									}
								}
								image: childImageSharp {
									fixed(width: 1200) {
										...GatsbyImageSharpFixed
									}
								}
							}
						}
					}
				}
			}
		}

		allPagePreview(
			limit: 13
			sort: {fields: date, order: DESC}
			filter: {
				tags: {in: $allTags},
				has_image: {eq: true},
				type: {in: ["article", "press"]}
			}
		) {
			relatedFragments: nodes {
				...Preview
			}
		}
	}
`

export const getArticleBackgroundImage: (mediaFrag: GraphMedia) => ImageType = mediaFrag => {
	if(!mediaFrag) return null
	const mobile = getImageFromField(mediaFrag, 'mobile')
	const desktop = getImageFromField(mediaFrag, 'image')
	if(!mobile || !desktop) return null
	return {
		...desktop,
		fixed: [
			mobile.fixed as FixedObject,
			{...desktop.fixed as FixedObject, media: '(min-width: 600px)'}
		]
	}
}
